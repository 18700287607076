import React from "react"

import Card from "react-bootstrap/Card"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IdentityNavigation from "../components/identity_navigation"
import CardDeck from "../components/card_deck"
import FormattedMarkdown from "../components/formatted_markdown"

import iconfinder_21_3319623 from "../images/iconfinder_21_3319623.png"

const IdentityPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Identity" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <IdentityNavigation location={location} />
      <CardDeck>
        <IdentityCard id="Identity" img={iconfinder_21_3319623} />
        <Card style={{ visibility: "hidden" }} />
        <Card style={{ visibility: "hidden" }} />
      </CardDeck>
    </Layout>
  )
}

const IdentityCard = props => {
  const intl = useIntl()
  return (
    <Card>
      <Card.Img src={props.img} />
      <Card.Body>
        <Card.Title>{intl.formatMessage({ id: props.id })}</Card.Title>
        <Card.Text>
          <FormattedMarkdown id={"Identity." + props.id} />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default IdentityPage
