import React from "react"

import NotLoggedIn from "./not_logged_in"
import Space from "./space"

const LoginRequired = ({ children }) => {
  return (
    <>
      <NotLoggedIn>
        🔒
        <Space />
      </NotLoggedIn>
      {children}
    </>
  )
}

export default LoginRequired
