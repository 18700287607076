import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const CardDeck = ({ children }) => {
  return (
    <Row xs={1} md={2} lg={3} xl={4}>
      {children.map(child => {
        return <Col className="mb-3">{child}</Col>
      })}
    </Row>
  )
}

export default CardDeck
